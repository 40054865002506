import React from 'react'
import { useCart } from 'react-use-cart';
import { formatPrice } from '../../../../../../src/utils/stripe'
import Button from '@pagerland/common/src/components/Button';

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: '1rem',
    boxShadow: '5px 5px 25px 0 rgba(46,61,73,.2)',
    backgroundColor: '#fff',
    borderRadius: '6px',
    maxWidth: '300px',
}

const quantityControlStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
}

const quantityButtonStyles = {
    fontSize: '16px',
    width: '30px',
    height: '30px',
    margin: '0 5px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
}

const removeButtonStyles = {
    fontSize: '13px',
    textAlign: 'center',
    color: '#fff',
    outline: 'none',
    padding: '8px 12px',
    boxShadow: '2px 5px 10px rgba(0,0,0,.1)',
    backgroundColor: '#ff6b6b',
    borderRadius: '6px',
    marginLeft: '10px',
    border: 'none',
    cursor: 'pointer',
}

const SkuCard = ({ sku }) => {
    const { addItem, updateItemQuantity, removeItem, inCart, getItem } = useCart();
    const isInCart = inCart(sku.id);
    const cartItem = isInCart ? getItem(sku.id) : null;
    const quantity = cartItem ? cartItem.quantity : 0;

    return (
        <div style={cardStyles}>
            <h4>{sku.name}</h4>
            {sku.image
                ? <img src={sku.image} alt={sku.name} style={{ width: '100%', maxHeight: '100px', height: 'auto', borderRadius: '6px' }} />
                : <img src="https://placehold.co/200x100" alt="No image" style={{ width: '100%', height: 'auto', borderRadius: '6px' }} />}
            <p>
                Price{' '}
                {formatPrice(sku.price, sku.currency)}
            </p>

            {isInCart ? (
                <>
                    <div style={quantityControlStyles}>
                        <button
                            style={quantityButtonStyles}
                            onClick={() => updateItemQuantity(sku.id, quantity - 1)}
                        >
                            -
                        </button>
                        <span>{quantity}</span>
                        <button
                            style={quantityButtonStyles}
                            onClick={() => updateItemQuantity(sku.id, quantity + 1)}
                        >
                            +
                        </button>
                        <button
                            style={removeButtonStyles}
                            onClick={() => removeItem(sku.id)}
                        >
                            Remove
                        </button>
                    </div>
                </>
            ) : (
                <Button
                    variant="brand"
                    onClick={() => { addItem(sku) }}
                >
                    ADD TO CART
                </Button>
            )}
        </div>
    )
}

export default SkuCard