import Icon from "@pagerland/common/src/components/Icon";
import textToMultiline from "@pagerland/common/src/utils/textToMultiline";
import Award from "@pagerland/icons/src/line/Award";
import Bath from "@pagerland/icons/src/line/Bath";
import BedDouble from "@pagerland/icons/src/line/BedDouble";
import Building from "@pagerland/icons/src/line/Building";
import { default as CalendarAlt } from "@pagerland/icons/src/line/CalendarAlt";
import CarSideview from "@pagerland/icons/src/line/CarSideview";
import Cube from "@pagerland/icons/src/line/Cube";
import Envelope from "@pagerland/icons/src/line/Envelope";
import FacebookF from "@pagerland/icons/src/line/FacebookF";
import Instagram from "@pagerland/icons/src/line/Instagram";
import Linkedin from "@pagerland/icons/src/line/LinkedinAlt";
import MapMarker from "@pagerland/icons/src/line/MapMarker";
import Phone from "@pagerland/icons/src/line/Phone";
import ShieldCheck from "@pagerland/icons/src/line/ShieldCheck";
import ThumbsUp from "@pagerland/icons/src/line/ThumbsUp";
import User from "@pagerland/icons/src/line/User";
import WebGrid from "@pagerland/icons/src/line/WebGrid";
import Youtube from "@pagerland/icons/src/line/Youtube";
import React from "react";
import * as Yup from "yup";
import background from "./assets/background-1.jpg";
import background2x from "./assets/background-1@2x.jpg";
import backgroundempty from "./assets/backgroundempty.jpg";
import backgroundcourses from "./assets/backgroundcourses-21.png";
import backgroundcourses2x from "./assets/backgroundcourses-21.png";
import contactImg from "./assets/contact.jpg";
import contactImg2x from "./assets/contact@2x.jpg";
import course22x from "./assets/course/baby-1@2x.jpg";
import course12x from "./assets/course/baby-2@2x.jpg";
import course2 from "./assets/course/thumbnails/baby-1.jpg";
import course1 from "./assets/course/thumbnails/baby-2.jpg";
import gallery10 from "./assets/gallery/gallery-10@2x.jpg";
import gallery11 from "./assets/gallery/gallery-11@2x.jpg";
import gallery12 from "./assets/gallery/gallery-12@2x.jpg";
import {
  default as gallery13,
  default as gallery14,
  default as gallery15,
} from "./assets/gallery/gallery-13@2x.jpg";
import gallery1 from "./assets/gallery/gallery-1@2x.jpg";
import gallery2 from "./assets/gallery/gallery-2@2x.jpg";
import gallery3 from "./assets/gallery/gallery-3@2x.jpg";
import gallery4 from "./assets/gallery/gallery-4@2x.jpg";
import gallery5 from "./assets/gallery/gallery-5@2x.jpg";
import gallery6 from "./assets/gallery/gallery-6@2x.jpg";
import gallery7 from "./assets/gallery/gallery-7@2x.jpg";
import gallery8 from "./assets/gallery/gallery-8@2x.jpg";
import gallery9 from "./assets/gallery/gallery-9@2x.jpg";
import gallery1Thumbnail from "./assets/gallery/thumbnails/gallery-1.jpg";
import gallery10Thumbnail from "./assets/gallery/thumbnails/gallery-10.jpg";
import gallery102xThumbnail from "./assets/gallery/thumbnails/gallery-10@2x.jpg";
import gallery11Thumbnail from "./assets/gallery/thumbnails/gallery-11.jpg";
import gallery112xThumbnail from "./assets/gallery/thumbnails/gallery-11@2x.jpg";
import gallery12Thumbnail from "./assets/gallery/thumbnails/gallery-12.jpg";
import gallery122xThumbnail from "./assets/gallery/thumbnails/gallery-12@2x.jpg";
import {
  default as gallery13Thumbnail,
  default as gallery14Thumbnail,
  default as gallery15Thumbnail,
} from "./assets/gallery/thumbnails/gallery-13.jpg";
import {
  default as gallery132xThumbnail,
  default as gallery142xThumbnail,
  default as gallery152xThumbnail,
} from "./assets/gallery/thumbnails/gallery-13@2x.jpg";
import gallery12xThumbnail from "./assets/gallery/thumbnails/gallery-1@2x.jpg";
import gallery2Thumbnail from "./assets/gallery/thumbnails/gallery-2.jpg";
import gallery22xThumbnail from "./assets/gallery/thumbnails/gallery-2@2x.jpg";
import gallery3Thumbnail from "./assets/gallery/thumbnails/gallery-3.jpg";
import gallery32xThumbnail from "./assets/gallery/thumbnails/gallery-3@2x.jpg";
import gallery4Thumbnail from "./assets/gallery/thumbnails/gallery-4.jpg";
import gallery42xThumbnail from "./assets/gallery/thumbnails/gallery-4@2x.jpg";
import gallery5Thumbnail from "./assets/gallery/thumbnails/gallery-5.jpg";
import gallery52xThumbnail from "./assets/gallery/thumbnails/gallery-5@2x.jpg";
import gallery6Thumbnail from "./assets/gallery/thumbnails/gallery-6.jpg";
import gallery62xThumbnail from "./assets/gallery/thumbnails/gallery-6@2x.jpg";
import gallery7Thumbnail from "./assets/gallery/thumbnails/gallery-7.jpg";
import gallery72xThumbnail from "./assets/gallery/thumbnails/gallery-7@2x.jpg";
import gallery8Thumbnail from "./assets/gallery/thumbnails/gallery-8.jpg";
import gallery82xThumbnail from "./assets/gallery/thumbnails/gallery-8@2x.jpg";
import gallery9Thumbnail from "./assets/gallery/thumbnails/gallery-9.jpg";
import gallery92xThumbnail from "./assets/gallery/thumbnails/gallery-9@2x.jpg";

import cert1 from "./assets/montessori-about/thumbnails/certificate1.jpg";
import cert2 from "./assets/montessori-about/thumbnails/certificate2.jpg";
import cert3 from "./assets/montessori-about/thumbnails/certificate3.jpg";
import cert12x from "./assets/montessori-about/certificate12x.jpg";
import cert22x from "./assets/montessori-about/certificate22x.jpg";
import cert32x from "./assets/montessori-about/certificate32x.jpg";
import venue22x from "./assets/montessori-about/playgroup-2@2x.jpg";
import venue32x from "./assets/montessori-about/playgroup-3@2x.jpg";
import venue2 from "./assets/montessori-about/thumbnails/playgroup-2.jpg";
import venue3 from "./assets/montessori-about/thumbnails/playgroup-3.jpg";
import thank_you from "./assets/thank-you.png";
import female from "./assets/parents/photo-female.jpg";
import femalex2 from "./assets/parents/photo-female@2x.jpg";
import male from "./assets/parents/photo-male.jpg";
import malex2 from "./assets/parents/photo-male@2x.jpg";
import posts12x from "./assets/posts/posts-1@2x.jpg";
import posts22x from "./assets/posts/posts-2@2x.jpg";
import posts32x from "./assets/posts/posts-3@2x.jpg";
import posts1 from "./assets/posts/thumbnails/posts-1.jpg";
import posts2 from "./assets/posts/thumbnails/posts-2.jpg";
import posts3 from "./assets/posts/thumbnails/posts-3.jpg";
import { default as toy1, default as toy12x } from "./assets/toys/toys-1.jpg";
import { default as toy2, default as toy22x } from "./assets/toys/toys-2.jpg";
import { default as toy3, default as toy32x } from "./assets/toys/toys-3.jpg";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default {
  title: "Montessori",
  navbar: {
    links: [
      {
        as: "a",
        href: "/",
        label: "Home",
      },
      {
        to: "register",
        "data-to": "register",
        label: "Register",
      },
      {
        to: "reviews",
        "data-to": "reviews",
        label: "Reviews",
      },
      {
        to: "venue",
        "data-to": "venue",
        label: "Venue",
      },
      {
        to: "about",
        "data-to": "about",
        label: "About",
      },
    ],
    actions: [
      {
        as: "a",
        href: "/page",
        label: "Blog",
      },
      {
        as: "a",
        href: "/shop",
        label: "Shop",
      },
      {
        as: "a",
        href: "/course/playgroup",
        label: "Join Playgroup",
      },
    ],
  },
  exterior: {
    sections: [
      {
        source: thank_you,
        ImgProps: {
          src: thank_you,
          srcSet: `${thank_you} 1x, ${thank_you} 2x`,
        },

        title: "Thank You for Your Purchase",
        text: "At the heart of our classroom—and everything we offer—is a deep respect for each child’s unique potential. With every order, you’re supporting a space where children are free to explore, discover, and grow at their own pace, guided by the Montessori approach and the care of AMI-trained educators. Thank you for believing in the power of respectful, purposeful learning.",
      },
    ],
  },
  navbarcourse: {
    links: [
      {
        to: "",
        "data-to": "",
        label: "Overview",
      },
      {
        to: "about",
        "data-to": "about",
        label: "Goal",
      },
      {
        to: "course",
        "data-to": "course",
        label: "About Course",
      },
      {
        to: "parents",
        "data-to": "parents",
        label: "Parents",
      },
      {
        to: "register",
        "data-to": "register",
        label: "Register",
      },
    ],
    actions: [
      {
        as: "a",
        href: "/page",
        label: "Blog",
      },

      {
        as: "a",
        href: "/",
        label: "Home",
      },
    ],
  },
  welcomecourse: {
    background: {
      src: background,
      srcSet: `${background} 1x, ${background2x} 2x`,
      alt: "Playgroup",
    },
    title: "Montessori Playgroups time for families ",
    price: "$300",
    features: [
      {
        IconProps: {
          icon: WebGrid,
        },
        title: "Up to 5 pax",
      },
      {
        IconProps: {
          icon: CarSideview,
        },
        title: "5 classes",
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: "Mobile Babies",
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: "Non-Mobile Babies",
      },
    ],
    cta: {
      to: "register",
      children: "Register",
    },
  },
  welcomeempty: {
    background: {
      src: backgroundempty,
      srcSet: `${backgroundempty} 1x, ${backgroundempty} 2x`,
      alt: "Courses",
    },
  },
  welcomecourses: {
    background: {
      src: backgroundcourses,
      srcSet: `${backgroundcourses} 1x, ${backgroundcourses2x} 2x`,
      alt: "Courses",
    },
    title: "Authentic Montessori Singapore",
    header: "Our mission is to empower parents with the knowledge and skills necessary for fostering their children’s optimal growth. Through our Montessori playgroups and continuous support, we aim to build parental confidence, ease parenting challenges, and enhance the joy of spending time with their children.",
    cta: {
      to: "register",
      children: "Register",
    },
    homepageCta: {
      to: "register",
      children: "Start learning now",
    },
  },
  welcome: {
    background: {
      src: background,
      srcSet: `${background} 1x, ${background2x} 2x`,
      alt: "Playgroup",
    },
    title: "The Montessori method provides a foundation for your child",
    price: "",
    features: [
      {
        IconProps: {
          icon: WebGrid,
        },
        title: "Independence",
      },
      {
        IconProps: {
          icon: CarSideview,
        },
        title: "Language",
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: "Concentration",
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: "Movement",
      },
    ],
    cta: {
      to: "about",
      children: "Learn more",
    },
  },
  reviews:
  {
    reviews: [
      {
        author: "Debbie, mum of Matthew",
        text: "My child likes and trusts Teacher Aleksandra very much. \
        She can face children calmly, and her fridom within limits is so clear.\
        The whole environment and teacher are very integrated, and \
        she is also very kind and friendly outside class. \
        She can give me excellent advice when I have some doubts.\
        Thank you, we enjoyed the playgroup!"
      },
      {
        author: "Jasmine, mum of Joshua",
        text: "We thoroughly enjoyed playgroup sessions with Ola! \
        She is patient and knowledgeable in montessori concepts. \
        The playgroup brings about a sense of calm and order although there are so many toddlers around. \
        You can see how the children enjoy being in the prepared environment \
        by the way they just gravitate towards the materials. \
        I have seen my own son focus for longer than he usually would.\
        Ola guides him through the materials and more importantly, \
        guides the adults caregivers on how to approach situations. \
        This playgroup has been a crucial part of my journey as a first time mum."
      }
    ]
  },

  about: {
    title:
      "Meet Aleksandra (Ola)",
    text: "Master of Physiotherapy and BA in teaching with over 10 years of experience working \
    with children aged 0-12 in Poland, India, and Singapore. \
    Aleksandra holds AMI Diploma 0-3  (Association Montessori Internationale) \
    and Montessori 3-6 certificate from Montessori Education Poland.\
    She has also completed specialisation course in 0-12mo in Child Physiotherapy.",
    posttext: " Aleksandra is a mother to a Montessori-raised son and applies positive discipline and Nonviolent Communication(NVC) \
    principles both at work and at home. In her free time, she enjoys learning about nutrition and physiotherapy for baby \
    development, practicing yoga, and going on hikes. She has climbed Gunung Agung(3031m), Everest Base Camp and\
    three passes, and Kala Pattar(5644m). Follow her on Instagram at @practicalmontessori for Montessori tips and\
    updates on her motherhood journey.",
    gallery: [
      {
        source: cert1,
        ImgProps: {
          src: cert1,
          srcSet: `${cert1} 1x, ${cert12x} 2x`,
        },
      },
      {
        source: cert2,
        ImgProps: {
          src: cert2,
          srcSet: `${cert2} 1x, ${cert22x} 2x`,
        },
      },
      {
        source: cert3,
        ImgProps: {
          src: cert3,
          srcSet: `${cert32x} 1x, ${cert32x} 2x`,
        },
      },
    ],
  },
  venue: {
    sections: [
      {
        source: venue2,
        ImgProps: {
          src: venue22x,
          srcSet: `${venue22x} 1x, ${venue22x} 2x`,
        },

        title: "Learning, Respect, and Nurturing Children's Potential.",
        text: "Welcome to our Montessori classroom, a centre for learning the Montessori approach and nurturing\
        the potential of children. Our focus is on respect and the belief that every child has unique abilities\
        and potential. In our Montessori classroom, children are free to explore and discover at their own\
        pace, with the support and guidance of AMI trained Montessori educators. ",
      },
      {
        source: venue3,
        ImgProps: {
          src: venue32x,
          srcSet: `${venue32x} 1x, ${venue32x} 2x`,
        },
        title: "Materials and Activities for Supporting Children's Development",
        text: "We offer a range of materials and activities that are designed to support the child's natural\
         development and help them to build important skills and abilities. Parents are welcome to observe and\
          learn more about the Montessori approach and how to support their child's development at home. \
          We look forward to welcoming you to our Montessori classroom and supporting you on your parenting journey.",
      },
    ],
  },
  parents: {
    title: "What parents say about us?",
    comments: [
      {
        author: "old",
        text: "old My child likes and trusts Teacher Aleksandra very much. \
         She can face children calmly, and her fridom within limits is so clear.\
          The whole environment and teacher are very integrated, and \
          she is also very kind and friendly outside class. \
          She can give me excellent advice when I have some doubts.\
           Thank you, we enjoyed the playgroup!",
        AvatarProps: {
          src: male,
          srcSet: `${male} 1x, ${malex2} 2x`,
        },
      },
      {
        author: "old Jasmine, mum of Joshua",
        text: "old We thoroughly enjoyed playgroup sessions with Ola! \
        She is patient and knowledgeable in montessori concepts. \
        The playgroup brings about a sense of calm and order although there are so many toddlers around. \
        You can see how the children enjoy being in the prepared environment \
        by the way they just gravitate towards the materials. \
        I have seen my own son focus for longer than he usually would.\
        Ola guides him through the materials and more importantly, \
        guides the adults caregivers on how to approach situations. \
        This playgroup has been a crucial part of my journey as a first time mum.",
        AvatarProps: {
          src: female,
          srcSet: `${female} 1x, ${femalex2} 2x`,
        },
      },
    ],
  },
  posts: {
    sections: [
      {
        ImgProps: {
          src: posts2,
          srcSet: `${posts2} 1x, ${posts22x} 2x`,
        },
        title: "8 tips to teach your child dress and undress by themselves.",
        text: "Our job as parents is to help our children become independent and build their self esteem, let them feel that they are capable of doing everything for themselves. But this is a long process so take it easy...",
        cta: {
          as: "a",
          label: "Learn more",
          href: "https://www.instagram.com/p/CRqCQDOhJRe/",
        },
      },
      {
        ImgProps: {
          src: posts1,
          srcSet: `${posts1} 1x, ${posts12x} 2x`,
        },
        title: "Montessori friendly toy guide.",
        text: "There is so much on the market and the information provided by producers always says \"it's the best toy to support your child's development\". But is it really?🤔",
        cta: {
          as: "a",
          label: "Learn more",
          href: "https://www.instagram.com/p/CPIHFwXBfaZ",
        },
      },
      {
        ImgProps: {
          src: posts3,
          srcSet: `${posts3} 1x, ${posts32x} 2x`,
        },
        title: "Why children repeat the same activity over and over again?",
        text: "Why is he climbing those stairs again?! 🤔 This might be a question you ask yourself a lot and find your child's behaviour really annoying sometimes. But there is a simple yet fascinating answer to this instinct all babies and small children have. ",
        cta: {
          as: "a",
          label: "Learn more",
          href: "https://www.instagram.com/p/CLvanWaB5ck",
        },
      },
    ],
  },
  gallery: {
    photos: [
      {
        source: gallery1,
        ImgProps: {
          src: gallery1Thumbnail,
          srcSet: `${gallery1Thumbnail} 1x, ${gallery12xThumbnail} 2x`,
        },
      },
      {
        source: gallery2,
        ImgProps: {
          src: gallery2Thumbnail,
          srcSet: `${gallery2Thumbnail} 1x, ${gallery22xThumbnail} 2x`,
        },
      },
      {
        source: gallery3,
        ImgProps: {
          src: gallery3Thumbnail,
          srcSet: `${gallery3Thumbnail} 1x, ${gallery32xThumbnail} 2x`,
        },
      },
      {
        source: gallery4,
        ImgProps: {
          src: gallery4Thumbnail,
          srcSet: `${gallery4Thumbnail} 1x, ${gallery42xThumbnail} 2x`,
        },
      },
      {
        source: gallery5,
        ImgProps: {
          src: gallery5Thumbnail,
          srcSet: `${gallery5Thumbnail} 1x, ${gallery52xThumbnail} 2x`,
        },
      },
      {
        source: gallery6,
        ImgProps: {
          src: gallery6Thumbnail,
          srcSet: `${gallery6Thumbnail} 1x, ${gallery62xThumbnail} 2x`,
        },
      },
      {
        source: gallery7,
        ImgProps: {
          src: gallery7Thumbnail,
          srcSet: `${gallery7Thumbnail} 1x, ${gallery72xThumbnail} 2x`,
        },
      },
      {
        source: gallery8,
        ImgProps: {
          src: gallery8Thumbnail,
          srcSet: `${gallery8Thumbnail} 1x, ${gallery82xThumbnail} 2x`,
        },
      },
      {
        source: gallery9,
        ImgProps: {
          src: gallery9Thumbnail,
          srcSet: `${gallery9Thumbnail} 1x, ${gallery92xThumbnail} 2x`,
        },
      },
      {
        source: gallery10,
        ImgProps: {
          src: gallery10Thumbnail,
          srcSet: `${gallery10Thumbnail} 1x, ${gallery102xThumbnail} 2x`,
        },
      },
      {
        source: gallery11,
        ImgProps: {
          src: gallery11Thumbnail,
          srcSet: `${gallery11Thumbnail} 1x, ${gallery112xThumbnail} 2x`,
        },
      },
      {
        source: gallery12,
        ImgProps: {
          src: gallery12Thumbnail,
          srcSet: `${gallery12Thumbnail} 1x, ${gallery122xThumbnail} 2x`,
        },
      },
      {
        source: gallery13,
        ImgProps: {
          src: gallery13Thumbnail,
          srcSet: `${gallery13Thumbnail} 1x, ${gallery132xThumbnail} 2x`,
        },
      },
      {
        source: gallery14,
        ImgProps: {
          src: gallery14Thumbnail,
          srcSet: `${gallery14Thumbnail} 1x, ${gallery142xThumbnail} 2x`,
        },
      },
      {
        source: gallery15,
        ImgProps: {
          src: gallery15Thumbnail,
          srcSet: `${gallery15Thumbnail} 1x, ${gallery152xThumbnail} 2x`,
        },
      },
    ],
  },
  contact: {
    title: "Contact",
    thumbnail: {
      src: contactImg,
      srcSet: `${contactImg} 1x, ${contactImg2x} 2x`,
    },
    details: {
      title: "Contact Aleksandra Gorgol to join one of her playgroups",
      text: "Aleksandra is a Master of Physiotherapy and BA in teaching. During her 10+ years of experience as an educator, she has been working with children 0-12 years old, in Singapore, Poland, and India. She is an AMI (Association Montessori Internationale) 0-3 trained educator and finished Montessori 3-6 course in Poland.",
      info: [
        {
          icon: User,
          text: "Aleksandra Gorgol",
        },
        {
          icon: Building,
          text: "Les Oliviers",
        },
        {
          icon: MapMarker,
          text: textToMultiline`66 Namly Pl, Singapore 267211`,
          props: {
            as: "a",
            href: "https://google.com/maps/place/66+Namly+Pl,+Singapore+267211",
          },
        },
        {
          icon: Phone,
          text: "+65 8310 ****",
        },
        {
          icon: Envelope,
          text: "aleksandra.gorgol@gmail.com",
        },
      ],
      socialLinks: [
        {
          icon: FacebookF,
          href: "https://www.facebook.com/profile.php?id=100069662023423",
          title: "Facebook",
        },
        {
          icon: Instagram,
          href: "https://www.instagram.com/practicalmontessori/",
          title: "Instagram",
        },
        {
          icon: Linkedin,
          href: "https://sg.linkedin.com/in/aleksandra-gorgol-ab4274167",
          title: "Linkedin",
        },
      ],
    },
    form: {
      title: "Questions? Send msg!",
      sendButtonText: "Send",
      validationSchema: Yup.object({
        firstName: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Required"),
        email: Yup.string().email("Must be an email").required("Required"),
        message: Yup.string()
          .min(1, "Must be at least 20 characters")
          .required("Required"),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: (values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact-demo", ...values }),
        })
          .then(() => {
            alert("Success");
            actions.resetForm();
          })
          .catch(() => {
            alert("Error");
          })
          .finally(() => actions.setSubmitting(false));
      },
      fields: [
        {
          name: "firstName",
          label: "First name",
          placeholder: "ie. Ann Lee",
          initialValue: "",
          prefix: <Icon icon={User} />,
        },
        {
          name: "phone",
          label: "Phone",
          placeholder: "ie. 5556-7223",
          initialValue: "",
          prefix: <Icon icon={Phone} />,
        },
        {
          name: "email",
          label: "E-mail",
          placeholder: "ie. ann.lee@email.com",
          type: "email",
          initialValue: "",
          prefix: <Icon icon={Envelope} />,
        },
        {
          name: "message",
          label: "Message",
          placeholder: "Start typing here...",
          multiline: true,
          initialValue: "",
        },
      ],
    },
  },
  register: {
    title: "Register",
    thumbnail: {
      src: contactImg,
      srcSet: `${contactImg} 1x, ${contactImg2x} 2x`,
    },
    details: {
      title: "Join the learning journey",
      text: "Ola is a Master of Physiotherapy and holds a BA in teaching. \
       In addition, she holds an AMI Diploma 0-3, Montessori Education certificate for 3-6 Montessori and has been teaching children for over a decade. \
      Ola is also a mother and enjoys raising her own son the Montessori way. ",
      info: [
        {
          icon: User,
          text: "Aleksandra Gorgol",
        },
        {
          icon: Building,
          text: "Les Oliviers",
        },
        {
          icon: MapMarker,
          text: textToMultiline`66 Namly Pl, Singapore 267211`,
          props: {
            as: "a",
            href: "https://google.com/maps/place/66+Namly+Pl,+Singapore+267211",
          },
        },
        {
          icon: Phone,
          text: "+65 8310 ****",
        },
        {
          icon: Envelope,
          text: "aleksandra.gorgol@gmail.com",
        },
      ],
      socialLinks: [
        {
          icon: FacebookF,
          href: "https://www.facebook.com/profile.php?id=100069662023423/",
          title: "Facebook",
        },
        {
          icon: Instagram,
          href: "https://www.instagram.com/practicalmontessori/",
          title: "Instagram",
        },
        {
          icon: Linkedin,
          href: "https://sg.linkedin.com/in/aleksandra-gorgol-ab4274167",
          title: "Linkedin",
        },
      ],
    },
    form: {
      title: "Register for the course",
      sendButtonText: "Send",
      validationSchema: Yup.object({
        email: Yup.string().email("Must be an email").required("Required"),
        courseDate: Yup.string()
          .min(3, "Please select date")
          .required("Required"),
        dob: Yup.string()
          .min(3, "Please select Child's DoB")
          .required("Required"),
        courseNam: Yup.string()
          .min(3, "Please select course")
          .required("Required"),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: (values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "register", ...values }),
        })
          .then(() => {
            alert("Success");
            actions.resetForm();
          })
          .catch(() => {
            alert("Error");
          })
          .finally(() => actions.setSubmitting(false));
      },
      fields: [
        {
          name: "firstName",
          label: "First name",
          placeholder: "ie. Ann Lee",
          initialValue: "",
          prefix: <Icon icon={User} />,
        },
        {
          name: "phone",
          label: "Phone (Optional)",
          placeholder: "ie. 5556-7223",
          initialValue: "",
          prefix: <Icon icon={Phone} />,
        },
        {
          name: "email",
          label: "E-mail",
          placeholder: "ie. ann.lee@email.com",
          type: "email",
          initialValue: "",
          prefix: <Icon icon={Envelope} />,
        },
        {
          name: "dob",
          label: "Child's Date of Birth",
          placeholder: "YYYY-MM-DD",
          type: "date", // If using HTML5 <input type="date">, ensure browser support.
          initialValue: "",
          prefix: <Icon icon={CalendarAlt} />,
        },
      ],
      dropdown: {
        name: "courseDate",
        label: "Course Date",
        initialValue: "",
        type: "select",
        prefix: <Icon icon={CalendarAlt} />,
        options: [
          {
            name: "Saturday",
            value: "Saturday",
            label: "Saturday 9:00AM 5-weeks",
          },
          {
            name: "Open",
            value: "Open",
            label: "Open Date 3:30PM 5-weeks",
          },
        ],
      },
    },
  },
  montessoriItems: {
    title: 'Explore our top learning opportunities',
    header: "Interested in learning about and implementing the Montessori and respectful parenting approach? \
    Start today and select one of the following:",
    properties: [
      {
        ImgProps: {
          src: toy1,
          srcSet: `${toy1} 1x, ${toy12x} 2x`,
        },
        LinkProps: {
          as: "a",
          href: "https://www.carousell.sg/p/wooden-music-toys-gift-box-1087787448",
        },
        title: "Wooden Music Toys",
        price: "$62",
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: "hand-eye coordination",
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: "visual and auditoy stimuli",
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: "5+ months",
          },
        ],
      },
      {
        ImgProps: {
          src: toy2,
          srcSet: `${toy2} 1x, ${toy22x} 2x`,
        },
        LinkProps: {
          as: "a",
          href: "https://www.carousell.sg/p/montessori-toy-box-with-knitted-ball-1055788986",
        },
        title: "Knitted ball box",
        price: "$30",
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: "hand-eye coordination",
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: "object permanence",
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: "10+ months",
          },
        ],
      },
      {
        ImgProps: {
          src: toy3,
          srcSet: `${toy3} 1x, ${toy32x} 2x`,
        },
        LinkProps: {
          as: "a",
          href: "https://www.carousell.sg/p/wooden-toys-gift-set-1087788801",
        },
        title: "Wooden Toys",
        price: "$65",
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: "visual and auditoy stimuli",
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: "hand-eye coordination",
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: "4+ Months",
          },
        ],
      },
    ],
  },
  footer: {
    title: "About Montessori Method",
    text: textToMultiline`In the world full of confusing information about raising children, Montessori philosophy comes with calming spaces, practical information and simple solutions to make your (and your child) life more peaceful. When Maria Montessori started her research she observed thousands of children across the world. She observed that in the first years of a child's life they all develop a very similar set of skills. Her method takes advantage of the understanding of the human being and human development. Applying it lets us empower child and let them grow to their full potential . `,
    cta: {
      as: "a",
      label: "Learn more about montessori approach",
      href: "/shop",
    },
    features: [
      {
        IconProps: {
          icon: Cube,
        },
        title: "Supporting natural development of children",
        text: 'Most popular quote  Montessori Method is "Help me to do it myself", by her observation she understood that to foster child\'s development we have to let them grow for themselves',
      },
      {
        IconProps: {
          icon: ThumbsUp,
        },
        title: "Helping children reach their full pottential",
        text: '"Education should no longer be mostly imparting of knowledge, but must take a new path, seeking the release of human potentialities." Maria Montessori',
      },
      {
        IconProps: {
          icon: ShieldCheck,
        },
        title: "Assisting in preparation of the home environment",
        text: "Learning happens through all the senses and hands-on experience. Thus home environment should be simple yet beautiful and safe place for children to explore.",
      },
      {
        IconProps: {
          icon: Award,
        },
        title: "Giving parents the tools to feel confident in parenting",
        text: "The adult is a guide who observes and gives freedom. They should provide limits, prepare and change the s]paceto enjoy life together with child",
      },
    ],
    socialLinks: [
      {
        icon: Youtube,
        href: "https://www.instagram.com/practicalmontessori/",
        title: "YouTube",
      },
      {
        icon: FacebookF,
        href: "https://www.facebook.com/profile.php?id=100069662023423/",
        title: "Facebook",
      },
      {
        icon: Instagram,
        href: "https://www.instagram.com/practicalmontessori/",
        title: "Instagram",
      },
      {
        icon: Linkedin,
        href: "https://sg.linkedin.com/in/aleksandra-gorgol-ab4274167",
        title: "Linkedin",
      },
    ],
  },
  newsletter: {
    title: "Stay up to date",
    text: "Sign up to newsletter",
    fields: [
      {
        name: "email",
        placeholder: "Your email address",
        type: "email",
        initialValue: "",
      },
    ],
    buttonText: "Sign up",
    validationSchema: Yup.object({
      email: Yup.string().email("Must be an email").required("Required"),
    }),
    onSubmit: (values, actions) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "newsletter", ...values }),
      })
        .then(() => {
          alert("Success");
          actions.resetForm();
        })
        .catch(() => {
          alert("Error");
        })
        .finally(() => actions.setSubmitting(false));
    },
  },
  copyright: "© 2020 Coffeecream Themes",
};
