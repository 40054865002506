import React, { useState } from 'react'
import Button from '@pagerland/common/src/components/Button';
import Box from '@pagerland/common/src/components/Box';
import { useCart } from "react-use-cart";
import { getStripe, formatPrice } from '../../../../../../src/utils/stripe';

const CartOverview = () => {
    const [loading, setLoading] = useState(false)
    const {
        cartTotal,
        items,
        emptyCart,
        totalUniqueItems,
    } = useCart();

    const checkout = async () => {
        setLoading(true);
        try {
            const stripe = await getStripe();
            const { error } = await stripe.redirectToCheckout({
                mode: 'payment',
                lineItems: items.map((item) => ({
                    price: item.id,
                    quantity: item.quantity,
                })),
                successUrl: `${window.location.origin}/thank-you/`,
                cancelUrl: `${window.location.origin}/shop`,
            });
            if (error) {
                console.error(error);
            }
        } catch (err) {
            console.error('Error in handleSubmit:', err);
        }
        setLoading(false);
    }

    return (
        <Box mt={4} mb={2} style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap' }}>
            <Button
                variant="brand"
                disabled={loading}
                onClick={checkout}
            >
                {loading ? 'Loading...' : `Checkout (${formatPrice(cartTotal, 'SGD')})`}
            </Button>
            <Button
                onClick={emptyCart}>
                Clear cart
            </Button>
        </Box>
    )
}

export default CartOverview