import React from 'react';
import PropTypes from 'prop-types';

import Box from '@pagerland/common/src/components/Box';
import ParallaxBackground from '../../components/ParallaxBackground';

import data from '../../data';

const WelcomeEmpty = ({
  name,
  background,
  WrapperProps,
}) => (
  <Box name={name} {...WrapperProps}>
    <ParallaxBackground {...background} />
  </Box >
);

WelcomeEmpty.propTypes = {
  name: PropTypes.string.isRequired,
  WrapperProps: PropTypes.object,
  background: PropTypes.object,
};

WelcomeEmpty.defaultProps = {
  WrapperProps: {
    pt: {
      _: 80,
      lg: 80,
    },
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
  },
  ...data.welcomeempty,
};

export default WelcomeEmpty;
