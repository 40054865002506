import React from 'react'
import Box from "@pagerland/common/src/components/Box";
import Grid from "@pagerland/common/src/components/Grid";
import SkuCard from './SkuCard'

import TagAlt from "@pagerland/icons/src/line/TagAlt";

const conatinerStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '1rem 0 1rem 0',
}

export default function Skus({ name, productsData, category, WrapperProps, GridProps }) {

  const filteredItems = productsData.allStripePrice.edges.filter(({ node: price }) => {
    if (category) {
      return price.product?.description?.includes(category)
    }
    return true
  });

  return (
    <Box name={name} {...WrapperProps} style={conatinerStyles}>
      <Grid {...GridProps} gridTemplateColumns={{
        _: 'repeat(1, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
      }}>
        {filteredItems.map(({ node: price }) => {
          const newSku = {
            id: price.id,
            sku: price.id,
            name: price.product.name,
            price: price.unit_amount,
            currency: price.currency,
            image: price.product?.images ? price.product?.images[0] : '',
          }
          return <SkuCard key={price.product.id} sku={newSku} />
        })}
      </Grid>
    </Box>
  )

}

Skus.defaultProps = {
  category: null,
  WrapperProps: {
    pb: {
      _: "auto",
      md: "auto",
      lg: "auto",
    },
  },
  TitleProps: {
    borderTopWidth: "1px",
    borderTopColor: "gray.4",
    pt: 40,
    pb: {
      _: 3,
      md: 4,
      lg: 5,
    },
    as: "h2",
    variant: "h2",
  },
  HeaderProps: {
    borderTopWidth: "1px",
    borderTopColor: "gray.4",
    pt: 0,
    pb: {
      _: 3,
      md: 4,
      lg: 5,
    },
    as: "h5",
  },
  GridProps: {
    gridTemplateColumns: {
      _: "1fr",
      lg: "repeat(4, 2fr)",
    },
    gridGap: "32px",
  },
  CardProps: {
    p: 0,
    position: "relative",
    display: "block",
  },
  ImgProps: {
    width: "100%",
    display: "block",
  },
  CaptionProps: {
    py: 24,
    px: 4,
  },
  PriceBadgeProps: {
    position: "absolute",
    left: 0,
    top: 16,
  },
  PriceBadgeIconProps: {
    icon: TagAlt,
    fontSize: 24,
  },
  ItemTitleProps: {
    as: "span",
    variant: "h4",
    color: "#000",
    mb: 24,
  },
  FeaturesGridProps: {
    mt: 4,
    maxWidth: 320,
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "32px",
    gridRowGap: "8px",
  },
  FeatureProps: {
    flexBox: true,
    alignItems: "center",
  },
  FeatureIconProps: {
    fontSize: 24,
    mr: 2,
    color: "#000",
  },
  FeatureTitleProps: {
    variant: "small",
    color: "gray.1",
  },
};
